import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import { Layout, Main, Stack } from '@layout'
import Seo from '@widgets/Seo'
import React from 'react'
import { Heading, Link } from 'theme-ui'

const styles = {
  heading: {
    color: `alphaDarker`
  }
}

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Privacy Policy' subheader='' />
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【個人情報の管理】
        </Heading>
        個人情報を取り扱うにあたっては、「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【個人情報の利用目的】
        </Heading>
        Forestone（以下、「当サイト」とします）では、訪問者様からのお問い合わせやコメントをお受けした際に、お名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。
        これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
        また、コメントが当サイトの管理人（管理人の名前）に承認されますと、プロフィール画像とお名前（ハンドルネール）がコメントともに一般公開されますのでご了承ください。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【個人情報の第三者への開示】
        </Heading>
        当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        ・本人のご了解がある場合 ・法令等への協力のため、開示が必要となる場合
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【個人情報の開示、訂正、追加、削除、利用停止】
        </Heading>
        ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【Cookie（クッキー）】
        </Heading>
        当サイトでは、一部のコンテンツについて情報の収集にCookieを使用しています。
        Cookieは、ユーザーがサイトを訪れた際に、そのユーザーのコンピュータ内に記録されます。
        ただし、記録される情報には、ユーザー名やメールアドレスなど、個人を特定するものは一切含まれません。
        また、当サイトではユーザーの方々がどのようなサービスに興味をお持ちなのかを分析したり、ウェブ上での効果的な広告の配信のためにこれらを利用させていただく場合があります。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【当サイトが使用しているアクセス解析ツールについて】
        </Heading>
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくはこちらをご参照ください。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【当サイトの広告について】
        </Heading>
        当サイトでは「Amazonアソシエイト」などのアフィリエイトプログラムや第三者配信広告サービス「GoogleAdsense」を利用しています。
        GoogleAdsenseでは広告配信プロセスにおいてデータを収集するために、Cookieを使用しています。GoogleでCookieを使用することにより、インターネットにおけるご自身のサイトや他のサイトへのアクセス情報に基づいてユーザーに広告を配信することが可能になります。
        Cookieを使用しないように設定するにはこちらをご参照ください。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【著作権について】
        </Heading>
        当サイトに掲載されている情報についての著作権は放棄しておりません。
        著作権法により認められている引用の範囲である場合を除き「内容、テキスト、画像等」の無断転載・使用を固く禁じます。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【免責事項】
        </Heading>
        当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
        当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。
        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【プライバシーポリシーの変更について】
        </Heading>
        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
        修正された最新のプライバシーポリシーは常に本ページにて開示されます。
        【お問い合わせ】 当サイトへの、お問い合わせに関しては、
        <Link href={`/contact`}>こちら</Link>
        からどうぞ。
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
